import React, { useState } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { IPages } from 'apiFolder/directus-main';

import nswLogo from 'images/nsw-gov.svg';

import { H1, H2, Paragraph, Heading } from 'components/common/Styles';

import TypeFormComponent from 'components/common/TypeForm';
import { ContentArticle820 } from 'components/Content';
import Footer from 'components/Footer/Footer';
import Seo from 'components/seo';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';

interface IAbout {
	pageContext: {
		aboutMarkupShcema: IPages;
	};
}

const About: React.FC<IAbout> = ({ pageContext }) => {
	const [isMenuOpen, toggleMenu] = useState(false);
	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};

	return (
		<>
			<Seo
				title={`About Us: Our Mission, Vision & Why`}
				description='Find out why we created BookPhysio.com, an on-demand physiotherapy booking site that connects patients with experienced physiotherapists.'
				markupSchema={pageContext.aboutMarkupShcema.markup_schema}
			/>
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<ContentArticle820 isOpen={isMenuOpen}>
						<TopPadding />
						<Paragraph>About BookPhysio.com</Paragraph>
						<H1>We’ve got your back™</H1>
						<Paragraph>
							BookPhysio.com is an on-demand physiotherapy booking site created to connect patients with
							experienced and qualified physiotherapists. We aim to help everyday Australians access
							reliable, evidence-based health information and provide a streamlined connection to suitable
							treatment options via our on-demand booking engine.
						</Paragraph>
						<section>
							<Paragraph>
								<MainHeading>Supported by NSW GOV</MainHeading>
								<Row>
									<ParagraphContent>
										BookPhysio.com is proudly supported by The New South Wales Government, with
										funding and resources. We appreciate their commitment to us bringing efficient
										access to health information and healthcare services right around Australia.{' '}
									</ParagraphContent>
									<ImgBlock>
										<img src={nswLogo} alt='NSW GOV logo' />
									</ImgBlock>
								</Row>
							</Paragraph>
						</section>
						<section>
							<BgBlock>
								<BgBlockInner>
									<Paragraph>
										<MainHeading>What we stand for</MainHeading>
										<Heading>Misson</Heading>
										<ParagraphContent>
											Enable patients to book an appointment with a nearby physio in less than 60
											seconds.
										</ParagraphContent>
									</Paragraph>
									<Paragraph>
										<Heading>Objective</Heading>
										<ParagraphContent>
											Provide easy access to treatment options for patients suffering from pain or
											injury.
										</ParagraphContent>
									</Paragraph>
									<Paragraph>
										<Heading>Vision</Heading>
										<ParagraphContent>
											Make booking a physio appointment as easy as booking an Uber.
										</ParagraphContent>
									</Paragraph>
									<Paragraph>
										<Heading>Our Why</Heading>
										<ParagraphContent>
											“We didn’t create BookPhysio.com because we liked booking a Physiotherapist.
											Quite the opposite, actually”
										</ParagraphContent>
									</Paragraph>
									<Paragraph>
										Booking a physiotherapist was - until now - a process that involved researching,
										ringing, and being put on hold while the receptionist flipped through the
										calendar to find availability. Oftentimes, you would find that your preferred
										time slot is unavailable, which means sometimes waiting several weeks to be
										treated - and booking a physio where and when you wanted was near impossible.
									</Paragraph>
									<Paragraph>
										Now, with BookPhysio.com, you can bypass all the pain, frustration and
										time-wasting by booking an appointment with a nearby physio in less than 60
										seconds.
									</Paragraph>
								</BgBlockInner>
							</BgBlock>
						</section>
						<section>
							<MainHeading>Meet the team that has your back, and whatever else hurts too.</MainHeading>
							<LineDec />
							<Paragraph>
								As a team, we are committed to building and nurturing inclusive teams at every level,
								and we seek out individuals who bring unique perspectives and experiences and an
								eagerness to embrace diversity, equity, and inclusion.{' '}
								<HoveredInline>
									<Link to='/about/team'>Meet the BookPhysio.com team</Link>.
								</HoveredInline>
							</Paragraph>
							<Paragraph>
								<FoundersImage>
									<StaticImage src='../images/about-team.jpg' alt='team' width={820} height={545} />
								</FoundersImage>
							</Paragraph>
						</section>
						<section>
							<Paragraph>
								<MainHeading>Register your clinic</MainHeading>
								<ParagraphContent>
									We have a network of physiotherapy clinics right around Australia, and welcome new
									clinics looking to join our rapidly growing network. If you're a practitioner or
									practice manager looking for more patient bookings, then we’d love to hear from you.{' '}
								</ParagraphContent>
							</Paragraph>
							<TypeFormComponent>
								<TextHovered>Click here to to register your clinic</TextHovered>
							</TypeFormComponent>
						</section>
						<section>
							<Paragraph>
								<MainHeading>Our approach to content creation</MainHeading>
								<Paragraph>
									The BookPhysio.com team is committed to bringing you accurate, trustworthy, and
									unbiased health information. Each piece of content is developed by our in-house
									editorial team in partnership with expert writers, medical reviewers, and
									fact-checkers. We appreciate your curiosity about our editorial process and are
									proud to share our rigorous standards with you.
								</Paragraph>
								<Hovered>
									<Link to='/editorial-policy'>Read more about our content and processes</Link>
								</Hovered>
								<Paragraph>
									Meet the team behind that ensures we have your back, and whatever else hurts too.
								</Paragraph>
								<Hovered>
									<Link to='/editorial-team'>Meet the editorial team</Link>
								</Hovered>
							</Paragraph>
						</section>
						<section>
							<Paragraph>
								<MainHeading>Content Disclaimer</MainHeading>
								<Paragraph>
									BookPhysio.com is a digital publisher and does not offer personal health or medical
									advice. Call your local emergency services immediately or visit the nearest
									emergency room or hospital if you're facing a medical emergency. Before starting any
									nutrition, diet, exercise, fitness, medical, or wellness program, you should consult
									your healthcare provider.
								</Paragraph>
								<Hovered>
									<Link to='/content-disclaimer'>
										Click here for the full version of our Content Disclaimer
									</Link>
								</Hovered>
							</Paragraph>
						</section>
						<section>
							<Paragraph>
								<H2>Policies</H2>
								<MainHeading>{`Ad & Sponsorship Policy`}</MainHeading>
								<Paragraph>
									BookPhysio.com receives funding from advertisements, sponsored content, and other
									partnerships, such as affiliate programs. The reality is that funding from our
									advertisers allows us to cover more conditions and provide more powerful
									experiences, including video, animations, and input from a range of experts. Be
									assured that we’re absolutely inflexible about our editorial autonomy. Other than
									input about broad topic areas, we never allow sponsors to influence the content we
									create.
								</Paragraph>
								<Paragraph>
									We have specific ad and sponsorship guidelines we follow to ensure that advertising
									never gets in the way of editorial integrity. BookPhysio.com chooses the ads, does
									not endorse any products, distinguishes all ads from editorial content, and clearly
									distinguishes between sponsored and non-sponsored content.{' '}
								</Paragraph>
								<Hovered>
									<Link to='/advertising-policy'>
										{`Read more about our Ad & Sponsorship Policy`}
									</Link>
								</Hovered>
							</Paragraph>
						</section>
						<section>
							<Paragraph>
								<MainHeading>Terms and Conditions</MainHeading>
								<Paragraph>
									The terms and conditions which govern your use of the website made available at
									BookPhysio.com (Site) are set out below.
								</Paragraph>
								<Paragraph>
									The Site is provided by Local Physio Network Pty Ltd (ABN: 26 641 519 904) (we, us
									and our) to users of the Site (you and your).
								</Paragraph>
								<Paragraph>
									Through the Site we provide an information and booking service for people who are
									searching for physiotherapy and associated services in a specific geographical area.
								</Paragraph>
								<Paragraph>
									The Site sources information about clinics and physicians which offer physiotherapy
									services in Australia (Clinics) and the services and fees offered by those Clinics.
								</Paragraph>
								<Paragraph>These terms and conditions govern the use of the Site.</Paragraph>
								<Hovered>
									<Link to='/terms-and-conditions'>Read more about our Terms and Conditions</Link>
								</Hovered>
							</Paragraph>
						</section>
						<section>
							<Paragraph>
								<MainHeading>Privacy Policy</MainHeading>
								<Paragraph>
									Local Physio Network Pty Ltd (ABN: 26 641 519 904) (“BookPhysio.com”, “we”, “us” or
									“our”) provides users with:
									<ListItems>
										<Paragraph>
											<Item>
												(a) access to the Local Physio Network website available at
												BookPhysio.com (Site); and
											</Item>
										</Paragraph>
										<Paragraph>
											<Item>
												(b) any of BookPhysio.com’s services made available on the Site.
												(together, the “Services”)
											</Item>
										</Paragraph>
									</ListItems>
								</Paragraph>
								<Paragraph>
									This Privacy Policy explains how we collect, use, maintain and disclose Personal
									Information which we collect, including Personal Information we collect from those
									who register as users (“you”, “yours”) of the Site.
								</Paragraph>
								<Paragraph>
									This Privacy Policy applies to the Services provided by us or our subsidiaries or
									affiliated companies via the Site.
								</Paragraph>
								<Hovered>
									<Link to='/privacy-policy'>Read more about our Privacy Policy</Link>
								</Hovered>
							</Paragraph>
							<Line />
						</section>
						<section>
							<Paragraph>
								<MainHeading>Contact Us</MainHeading>
								<Paragraph>
									<div>Head office: 2/11 York Street, Sydney NSW 2000</div>
									<div>Call us Monday - Friday 9am - 6pm AEST</div>
									<Hovered>
										<a href='tel:+610290686658'>(02) 9068 6658</a>
									</Hovered>
								</Paragraph>
								<Paragraph>
									Please note, BookPhysio.com cannot answer questions or provide guidance about
									specific injury or treatment options for your case.
								</Paragraph>
								<Paragraph>
									To make a booking with a physiotherapist in your area, use our free{' '}
									<HoveredInline>
										<Link to='/near-me'>physio near me page</Link>
									</HoveredInline>{' '}
									to make an appointment.
								</Paragraph>
								<Paragraph>
									For other questions or to provide feedback, email{' '}
									<HoveredInline>
										<a href='mailto:team@bookphysio.com'>team@bookphysio.com</a>.
									</HoveredInline>
								</Paragraph>
								<Heading>Investors</Heading>
								<Paragraph>
									If you're an accredited investor, we would love to arrange a meeting and discuss our
									vision and business plan. Please fill in the form with your details and
									availability, and we'll reach out to you as soon as possible.{' '}
								</Paragraph>
								<Paragraph>
									You can also email us at{' '}
									<HoveredInline>
										<a href='mailto:team@bookphysio.com'>team@bookphysio.com</a>
									</HoveredInline>{' '}
									or call us at{' '}
									<HoveredInline>
										<a href='tel:+610290686658'>(02) 9068 6658</a>.
									</HoveredInline>
								</Paragraph>
								<Heading>Media</Heading>
								<Paragraph>Are you looking for a press release or media kit?</Paragraph>
								<Paragraph>
									Please{' '}
									<HoveredInline>
										<a
											href='https://localphysionetwork.typeform.com/to/k0sNSMDP'
											target='_blank'
											rel='noopener noreferrer'
										>
											fill in this quick form
										</a>
									</HoveredInline>{' '}
									and we'll get back to you ASAP with all the information and assets you need - we
									look forward to it!
								</Paragraph>
								<Heading>Careers</Heading>
								<Paragraph>
									We are always on the lookout for hard-working and talented (Engineers, UX, UI,
									Sales, Data) people to join our team.
								</Paragraph>
								<Paragraph>
									If you are passionate about challenging the status quo and want to improve the lives
									of our patients,{' '}
									<HoveredInline>
										<a
											href='https://localphysionetwork.typeform.com/to/k0sNSMDP'
											target='_blank'
											rel='noopener noreferrer'
										>
											fill in our form
										</a>
									</HoveredInline>{' '}
									and tell us why you want to work with us.
								</Paragraph>
							</Paragraph>
						</section>
					</ContentArticle820>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};

const TopPadding = styled.div`
	width: 100%;
	height: 55px;

	@media (max-width: 767px) {
		display: none;
	}
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;
const BgBlock = styled.div`
	width: 100%;
	background-color: #f7f8fa;
	padding: 0 0 20px;
	margin: 50px 0;
	border-radius: 6px;
	border: 1px solid transparent;
`;
const BgBlockInner = styled.div`
	width: 100%;
	padding: 0px 30px;
`;
const MainHeading = styled.div`
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #000000;
	margin-top: 40px;
	margin-bottom: 30px;
`;

const Line = styled.div`
	width: 100%;
	height: 1px;
	background-color: #000000;
	margin-top: 40px;
	margin-bottom: 40px;
`;
const ListItems = styled.div`
	padding-left: 40px;

	@media (max-width: 767px) {
		padding-left: 10px;
	}
`;
const Item = styled.div`
	padding-left: 15px;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		top: 8px;
		left: 0;
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background-color: #000000;
	}
`;
const ParagraphContent = styled.p`
	font-weight: 300;
	font-style: normal;
	font-size: 16px;
	line-height: 23px;
	color: #000000;
`;
const ImgBlock = styled.div`
	flex: 0 0 20%;
	margin-left: 20px;
	& img {
		max-height: 100px;
		object-fit: contain;
	}
`;

const Hovered = styled(Paragraph)`
	& a {
		color: ${({ theme }) => theme.palette.purp};
		transition: all 0.3s ease;
		cursor: pointer;
		&:hover {
			color: ${({ theme }) => theme.palette.main};
			text-decoration: none;
		}
	}
`;

const HoveredInline = styled(Hovered)`
	display: inline-block;
	margin: 0;
	&:first-child {
		margin: 0;
	}
`;

const TextHovered = styled(Paragraph)`
	color: ${({ theme }) => theme.palette.purp};
	transition: all 0.3s ease;
	text-decoration: underline;
	cursor: pointer;
	margin-bottom: 0px;
	&:first-child {
		margin-top: 0px;
	}
	&:hover {
		color: ${({ theme }) => theme.palette.main};
		text-decoration: none;
	}
`;
const LineDec = styled.div`
	position: relative;
	width: 100%;
	height: 3px;
	background-color: #424bec;
	margin-bottom: 40px;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 80px;
		width: 0;
		height: 0;
		border-left: 30px solid transparent;
		border-right: 30px solid transparent;
		border-top: 25px solid #424bec;
	}
	&:after {
		content: '';
		position: absolute;
		top: -4px;
		left: 80px;
		width: 0;
		height: 0;
		border-left: 30px solid transparent;
		border-right: 30px solid transparent;
		border-top: 25px solid #ffffff;
		z-index: 1;
	}
`;
const FoundersImage = styled.div`
	& img {
		border-radius: 6px;
	}
`;
export default About;
